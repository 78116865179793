<template>
  <div class="page-content d-flex align-items-center justify-content-center">
    <div class="row w-100 mx-0 auth-page">
      <div class="col-md-8 col-xl-10 mx-auto">
        <div class="card">
          <div class="auth-form-wrapper px-4 py-5">
            <h4 class="mb-4">Create a free account.</h4>
            <Alert />
            <form class="forms-sample" @submit.prevent="regist">
              <div class="row">
                <div class="col-xl-6 col-md-12">
                  <div class="form-group">
                    <label for="input-company-name">Company Name</label>
                    <input type="text" class="form-control" id="input-company-name" placeholder="Company Name" name="company-name" required v-model="form.company_name">
                  </div>
                  <div class="form-group">
                    <label for="input-company-brand">Company Brand</label>
                    <input type="text" class="form-control" id="input-company-brand" placeholder="Company Brand" name="company-brand" required v-model="form.company_brand">
                  </div>
                </div>
                <div class="col-xl-6 col-md-12">
                  <div class="form-group">
                    <label for="input-company-address">Company Address</label>
                    <textarea class="form-control" id="input-company-address" rows="7" placeholder="Company Address" required v-model="form.company_address"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-md-12">
                  <div class="form-group">
                    <label for="input-name">Name</label>
                    <input type="text" class="form-control" id="input-name" placeholder="Name" name="name" required v-model="form.name">
                  </div>
                </div>
                <div class="col-xl-6 col-md-12">
                  <div class="form-group">
                    <label for="input-phone-number">Phone Number</label>
                    <input type="tel" class="form-control" id="input-phone-number" placeholder="62xxx" name="phone_number" required v-model="form.phone_number">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-6 col-md-12">
                  <div class="form-group">
                    <label for="input-email">Email address</label>
                    <input type="email" class="form-control" id="input-email" placeholder="Email" required v-model="form.email">
                  </div>
                </div>
                <div class="col-xl-6 col-md-12">
                  <div class="form-group">
                    <label for="input-password">Password</label>
                    <input type="password" class="form-control" id="input-password" placeholder="Password" required v-model="form.password">
                  </div>
                </div>
              </div>
              <div class="mt-3">
                <button type="submit" class="btn btn-primary text-white mr-2 mb-2 mb-md-0">Sign up</button>
              </div>
              <a href="/auth/login" class="d-block mt-3 text-muted">Already a user? Sign in</a>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { VueReCaptcha } from 'vue-recaptcha-v3';

import authAPI from '../../../api/auth';

Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_RECAPTCHA_SITEKEY });

export default {
  name: 'Register',
  metaInfo: {
    title: 'Register',
  },
  data() {
    return {
      form: {
        company_name: '',
        company_brand: '',
        company_address: '',
        name: '',
        phone_number: '',
        email: '',
        password: '',
        captcha_token: '',
      },
    };
  },
  methods: {
    async regist() {
      await this.$recaptchaLoaded();
      this.form.captcha_token = await this.$recaptcha();
      console.log(this.form);

      const loader = this.$loading.show();
      await authAPI.register(this.form)
        .then(async (res) => {
          if (!res.error) {
            await this.$store.dispatch('ui/alertSuccess', 'Register success! Please check your email for verification');
            this.$router.push('/auth/login');
          } else {
            await this.$store.dispatch('ui/alertError', res.error.message);
            this.showAlert();
          }
        })
        .catch(async () => {
          await this.$store.dispatch('ui/alertError', 'Something went wrong');
          this.showAlert();
        });
      loader.hide();
    },
  },
  beforeMount() {
    this.$recaptchaInstance.showBadge();
  },
  beforeDestroy() {
    this.$recaptchaInstance.hideBadge();
  },
};
</script>
